'use strict';

(function() {
  class SiteMapCtrl {
    constructor($scope, $rootScope, Auth, Util, Site, $state, usSpinnerService, $timeout, NgMap, appConfig, $window) {
      this.API_KEY = appConfig.GOOGLE_API_KEY;
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.$state = $state;
      this.Site = Site;
      this.allSites = [];
      this.loading = false;
      this.currentPosition = null;
      this.showPopups = false;
      this.usSpinnerService = usSpinnerService;
      this.appConfig = appConfig;
      this.getAddress = Util.getAddress;
      this.getTotalCapacity = Util.getTotalCapacity;
      this.graphWidth = 150;
      this.graphHeight = 150;

      let w = angular.element($window);

      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          this.init();
        } else {
          $state.go('login');
        }
      });
      this.$scope.$on('siteAdded', (event, site) => {
        // this.allSites.unshift(site);
        this.init();
        NgMap.getMap().then(function(map) {
          $timeout(()=> {
            google.maps.event.trigger(map, 'resize');
          });
        })
      });

      $scope.$watch('popups', (newVal, oldVal)=> {
        this.showPopups = newVal;
      });

      $scope.getWindowDimensions = function() {
        return {
          'h': w.height(),
          'w': w.width()
        };
      };

      $rootScope.$watch('findingsCategory' ,() => {
        this.filterSitesByFindingCategory($rootScope.findingsCategory);
      },true);

      $scope.$watch($scope.getWindowDimensions, (newValue, oldValue) => {
        if (newValue.w < 768) {
          this.graphWidth = newValue.w - 40;
          this.graphHeight = 150;
        } else if (newValue.w >= 768 && newValue.w <= 992) {
          this.graphWidth = 150;
          this.graphHeight = 150;
        } else if (newValue.w > 992 && newValue.w <= 1200) {
          this.graphWidth = 150;
          this.graphHeight = 150;
        } else if (newValue.w > 1200 && newValue.w <= 1360) {
          this.graphWidth = 150;
          this.graphHeight = 150;
        } else {
          this.graphWidth = 150;
          this.graphHeight = 150;
        }

        /* Chart options */
        this.options = {
          chart: {
            type: 'pieChart',
            width: this.graphWidth,
            height: this.graphHeight,
            donut: true,
            x: function(d) {
              return d.key;
            },
            y: function(d) {
              return d.y;
            },
            showLegend: false,
            showLabels: true,
            showValues: true,
            valueFormat: function(d) {
              return d3.format(',.0f')(d);
            },
            labelType: function(d) {
              return d.value
            },
            color: function(d, i) {
              return (d && d.color);
            },
            duration: 500
          }
        };
      }, true);

      w.bind('resize', function() {
        $scope.$apply();
      });
    }

    filterSitesByFindingCategory(findingsCategory) {
      let isAnyCategorySelected = false;
        this.allSites = _.filter(this.tempSites, site => {
          if((findingsCategory.cat5 && site.findingsCount.cat5 > 0) || (findingsCategory.cat4 && site.findingsCount.cat4 > 0) || (findingsCategory.cat3 && site.findingsCount.cat3 > 0) || (findingsCategory.cat2 && site.findingsCount.cat2 > 0) || (findingsCategory.cat1 && site.findingsCount.cat1 > 0) || (findingsCategory.cat0 && site.findingsCount.cat0 > 0) ) {
            isAnyCategorySelected = true;
            return site;
          }
        });
        if(!isAnyCategorySelected) {
          this.allSites = this.tempSites;
        }
    }

    generateGraphData() {
      this.allSites.forEach(site=> {
        site.totalFindingsCount = 0;
        if (site.findingsCount.cat1 ||
          site.findingsCount.cat2 ||
          site.findingsCount.cat3 ||
          site.findingsCount.cat4 ||
          site.findingsCount.cat5) {
          site.totalFindingsCount++;
        }
        site._data = [
          {
            key: "Cat 1 : Low",
            y: site.findingsCount.cat1,
            color: this.appConfig.colors.cat1
          },
          {
            key: "Cat 2 : Medium",
            y: site.findingsCount.cat2,
            color: this.appConfig.colors.cat2
          },
          {
            key: "Cat 3 : Serious",
            y: site.findingsCount.cat3,
            color: this.appConfig.colors.cat3
          },
          {
            key: "Cat 4 : Major",
            y: site.findingsCount.cat4,
            color: this.appConfig.colors.cat4
          },
          {
            key: "Cat 5 : Critical",
            y: site.findingsCount.cat5,
            color: this.appConfig.colors.cat5
          }
        ];
      });
    }

    init() {
      // this.$rootScope.$broadcast('resetFilterWindFarm');
      // this.$rootScope.$emit('isMapSelected', true);
      this.startSpin('spinner-2');
      this.loading = true;
      this.Site.get({ skipTurbinePopulate: true }).$promise
        .then(response=> {
          if (response.data && response.data.length) {
            var tempSites = _.filter(response.data, { isArchive: false });
            this.tempSites = tempSites;
            this.filterSitesByFindingCategory(this.$rootScope.findingsCategory);
            this.allSites = _.map(this.allSites, (site) => {
              if(site.location && site.location.position) {
                site.location.position = [site.location.position[1], site.location.position[0]];
              }
              return site;
            });
            if (this.allSites.length > 0) {
              if (this.allSites[0].location) {
                this.currentPosition = this.allSites[0].location.position;
              }
            }
            // this.generateGraphData();
          }
          this.loading = false;
          this.stopSpin('spinner-2');
        })
        .catch(err=> {
          console.log(err);
          this.loading = false;
          this.stopSpin('spinner-2');
        })
    }

    openSite(event, context, site) {
      context.$state.go('site', { id: site._id });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    populateImageName(site) {
      // console.log('site.findingsCount: ' , site.findingsCount);
      // site.mapImageName = 'low';
      if(site.findingsCount.cat5 > 0) {
        site.mapImageName = 'critical';
      } else if(site.findingsCount.cat4 > 0) {
        site.mapImageName = 'major';
      } else if(site.findingsCount.cat3 > 0) {
        site.mapImageName = 'serious';
      } else if(site.findingsCount.cat2 > 0) {
        site.mapImageName = 'medium';
      } else if(site.findingsCount.cat1 > 0) {
        site.mapImageName = 'low';
      } else {
        site.mapImageName = 'low';
      }
    }
  }

  angular.module('windmanagerApp')
    .controller('SiteMapCtrl', SiteMapCtrl);
})();
